@import "../../scss/vars";

.work {
				min-height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				&__wrapper {
								display: flex;
								gap: 1.5rem;
								flex-direction: column;

								@media screen and (max-width: 767px) {
												gap: 2rem;
								}
				}

				&__row {
								display: flex;
								justify-content: space-between;
								gap: 2rem;
								color: $font-color-2;
								font-weight: bolder;

								@media screen and (max-width: 767px) {
												flex-direction: column;
												justify-content: center;
												gap: 1rem;
								}

								&.active {
												color: $primary;
								}
				}
}