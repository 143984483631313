@font-face {
    font-family: Kanit;
    src: url(../fonts/Kanit/Kanit-Regular.ttf);
    font-weight: normal;
}

@font-face {
    font-family: Kanit;
    src: url(../fonts/Kanit/Kanit-Light.ttf);
    font-weight: lighter;
}

@font-face {
    font-family: Kanit;
    src: url(../fonts/Kanit/Kanit-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: Kanit;
    src: url(../fonts/Kanit/Kanit-ExtraBold.ttf);
    font-weight: bolder;
}

@font-face {
    font-family: Ubuntu;
    src: url(../fonts/Ubuntu/Ubuntu-Regular.ttf);
    font-weight: normal;
}

@font-face {
    font-family: Ubuntu;
    src: url(../fonts/Ubuntu/Ubuntu-Bold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: Ubuntu;
    src: url(../fonts/Ubuntu/Ubuntu-Light.ttf);
    font-weight: lighter;
}

@font-face {
    font-family: Avant;
    src: url(../fonts/ITC-Avant.otf);
}