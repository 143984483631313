$background-color: #1C1C1C;
$primary-color: #E68026;
$secondary-color: #633C29;

// $colors: red,
// orange,
// yellow,
// rgb(47, 0, 255);

$colors: orange;

// $primary: rgb(85, 38, 255);
$primary: #fad9ae;
$primary-pressed: darken($primary, 10%);
$primary-half-transparent: rgba($primary, .4);
$secondary: rgb(122, 37, 192);
$success: #5cff77;
$success-pressed: #53dd6a;

// dark bg values
$dark-bg: rgb(8, 7, 7);
$dark-bg-2: rgb(20, 15, 15);
$dark-bg-3: #1d1d1d;
$dark-bg-4: #000000fa;
$dark-bg-black: #000;
$dark-half-transparent: #00000081;
$dark-half-transparent2: #000000fa;

$menu-gradient-color-1: $dark-bg-black;
$menu-gradient-color-2: transparent;

// light bg values
$light-bg: rgb(240, 234, 234);

$background-1: $dark-bg;
$background-2: $dark-bg-2;
$background-3: $dark-bg-3;
$background-4: $dark-bg-4;
$background-absolute: $dark-bg-black;

$font-color-1: #fad9ae;
$font-color-2: rgba(255, 255, 255, 0.75);
$font-color-3: rgba(217, 217, 217, 0.6);

$gradient: radial-gradient($dark-bg, $background-absolute);
$menu-gradient: linear-gradient(0deg, $menu-gradient-color-1, $menu-gradient-color-2);
$test-gradient: radial-gradient($primary 0%, transparent 0%, transparent 100%);

$border-radius: 0.25rem;

// RESPONSIVE

// Small devices
@mixin sm {
				@media (max-width: 576px) {
								@content;
				}
}

// Medium devices
@mixin md {
				@media (max-width: 768px) {
								@content;
				}
}

// Large devices
@mixin lg {
				@media (max-width: 992px) {
								@content;
				}
}

// Extra large devices
@mixin xl {
				@media (min-width: 992px) and (max-width: 1199.98px) {
								@content;
				}
}

// Extra large devices
@mixin xxl {
				@media (min-width: 1200px) and (max-width: 1399.98px) {
								@content;
				}
}