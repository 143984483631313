@import "../../scss/vars.scss";

.hero {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 0;
	overflow: hidden;
	// pointer-events: none;

	&__inside {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2rem;
		flex-wrap: wrap;

		@media screen and (max-width: 767px) {
			text-align: center;
		}
	}

	&__image {
		width: 260px;
		height: 260px;
		border-radius: 50%;
		object-fit: cover;
		border: 30px solid transparent;
		outline: 2px solid #ffffff26;
		pointer-events: none;
	}

	&__wrapper {
		margin-bottom: -10%;
		width: 100%;
	}

	//&__circle {
	//	content: "";
	//	display: block;
	//	position: absolute;
	//	border-radius: 50%;
	//	width: 15px;
	//	height: 15px;
	//	background: #ffffff26;
	//	top: -7.5px;
	//	left: calc(50% - 7.5px);
	//	transition: all .3s ease-in-out;
	//}

	&__title {
		//color: $font-color-1;
		//font-size: 4rem;
		animation-name: hero-title;
		animation-duration: 2s;
		//line-height: 3rem;
		margin-bottom: 1rem;
		font-family: 'Avant', sans-serif;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: -.05em;
		line-height: 84%;
		font-size: 6rem;
		color: #fad9ae;
		text-align: center;

		@media screen and (max-width: 767px) {
			font-size: 3rem;
		}
	}

	&__title-header {
		color: $font-color-1;
		animation-name: hero-title;
		animation-duration: 2s;
		margin-bottom: 1rem;
		font-family: 'Avant', sans-serif;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: -.05em;
		line-height: 84%;
		font-size: 8.75rem;
		text-align: center;
		max-width: 56rem;
		margin: auto;

		@media screen and (max-width: 767px) {
			font-size: 3rem;
		}
	}

	&__subtitle-header {
		color: $font-color-3;
		font-size: 1.2rem;
		max-width: 350px;
		font-family: 'Avant', sans-serif;

		@media screen and (max-width: 767px) {
			margin: auto;
		}
	}

	&__subtitle {
		color: $font-color-3;
		font-size: 1.2rem;
		max-width: 350px;
		font-family: 'Avant', sans-serif;

		@media screen and (max-width: 767px) {
			margin: auto;
		}
	}

	&__social {
		position: absolute;
		bottom: 3rem;
		left: 1.5rem;
		display: flex;
		gap: 1.25rem;
		flex-direction: column;

		svg {
			color: #f8d7ad;
		}
	}
}