@import "../../scss/vars.scss";

.contact-me {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media screen and (max-width: 767px) {
		height: auto;
		min-height: 100vh;
	}

	&__wrapper {
		@media screen and (max-width: 767px) {
			flex-direction: column;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding-top: 1.5rem;
		font-family: 'Ubuntu', serif;
	}

	&__row {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.5rem;
	}

	&__label {
		color: $font-color-1;
		font-size: 1.4rem;
		font-weight: bolder;
		padding-bottom: 1rem;
	}

	&__input {
		min-height: 35px;
		min-width: 22rem;
		border-radius: $border-radius;
		outline: none;
		padding: 4px;
		font-family: 'Ubuntu', serif;
		border: 1px solid transparent;
		background: #4e4e4e2a;
		color: $font-color-1;
		backdrop-filter: blur(3px);

		&:hover {
			box-shadow: 0px 0px 2px 2px $primary;
		}

		&:focus {
			box-shadow: 0px 0px 2px 2px $primary;
			border: 1px solid $primary;
		}
	}

	&__button {
		background: transparent;
		border: 1px solid $primary;
		color: $font-color-1;

		display: inline-block;
		font-weight: bolder;
		line-height: 1.5;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		// cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		padding: 0.8rem 0.75rem;
		font-size: 1rem;
		border-radius: $border-radius;
		transition: color .15s ease-in-out,
		background-color .15s ease-in-out,
		border-color .15s ease-in-out,
		box-shadow .15s ease-in-out;

		&:hover {
			background-color: $primary;
			color: $background-1;
		}

		&:active {
			background-color: $primary-pressed;
		}

		&.sent {
			border-color: $success;

			&:hover {
				background-color: $success;
				color: $dark-bg-3;
			}

			&:active {
				background-color: $success-pressed;
			}
		}
	}

	&__subtitle {
		max-width: 450px;
	}

	textarea {
		resize: none;
	}
}