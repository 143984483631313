@import "../../scss/vars.scss";

.portfolio {
				display: flex;
				justify-content: center;
				padding: 4rem 0;
				position: relative;
				align-items: center;
				min-height: 100vh;

				@media screen and (max-width: 767px) {
								flex-direction: column;
								justify-content: center;
								align-items: center;
								overflow: hidden;
				}

				&__wrapper {
								display: flex;
								max-width: 650px;
								gap: 1rem;
								justify-content: center;
								flex-direction: column;

								@media screen and (max-width: 767px) {
												height: 100%;
								}
				}

				&__item {
								max-width: 300px;
								width: 300px;
								height: 300px;
								object-fit: cover;
								border: 1px solid;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: 0.5rem;
								backdrop-filter: blur(10px);
								// cursor: pointer;
								transition: all .4s ease-in-out;
								z-index: 2;

								&.active {
												// &::before {
												//     content: "";
												//     background: url(../images/wallet/wallet_analyzer_1.png);
												//     background-size: 150%;
												//     background-position: center;
												//     width: 100%;
												//     height: 100%;
												//     position: absolute;
												//     left: 0;
												//     top: 0;
												//     border-radius: 0.5rem;
												// }

												&::after {
																content: "";
																background: transparent;
																backdrop-filter: blur(15px);
																width: 100%;
																height: 100%;
																position: absolute;
																left: 0;
																top: 0;
																border-radius: 0.5rem;
												}
								}

								&.wallet {
												background-size: contain;
												background-image: url(../../resources/wallet/wallet-display.webp);

												&.active {
																@media screen and (max-width: 767px) {
																				margin: 5rem 0;
																}
												}
								}

								&.map-keeper {
												background-size: cover;
												background-image: url(../../resources/map-keeper/map-keeper-preview.jpg);

												.portfolio__phone-image {
																border-radius: .25rem;

																@media screen and (max-width: 767px) {
																				width: 95vw;
																}
												}
								}

								&.maserati {
												background-size: cover;
												background-image: url(../../resources/maserati/maserati_site.webp);
												background-position: center;

												.portfolio__phone-image {
																border-radius: .25rem;

																@media screen and (max-width: 767px) {
																				width: 95vw;
																}
												}
								}
				}

				&__details {
								width: 300px;
								height: 300px;
								font-size: 3rem;
								backdrop-filter: blur(5px);
								// cursor: pointer;
								perspective: 1000px;

								&:hover {
												.test-animation {
																animation: test-animation 2s;
												}
								}

								.test-animation {
												height: 100%;
												width: 100%;
												background: $test-gradient;
												position: absolute;
												z-index: -1;
								}


								&.flipped {
												.portfolio__details-wrapper {
																transform: rotateY(180deg);
												}
								}
				}

				&__details-wrapper {
								position: relative;
								width: 100%;
								height: 100%;
								text-align: center;
								transition: transform 0.8s;
								transform-style: preserve-3d;
								border: 1px solid;
								border-radius: 0.5rem;

								&:hover {}
				}

				&__details-front,
				&__details-back {
								position: absolute;
								width: 100%;
								height: 100%;
								-webkit-backface-visibility: hidden;
								/* Safari */
								backface-visibility: hidden;
								top: 0;
								left: 0;
								padding: 1rem;
				}

				&__details-front {
								display: flex;
								justify-content: space-between;
								flex-direction: column;
								height: 100%;
				}

				&__details-back {
								display: flex;
								justify-content: space-between;
								flex-direction: column;
								height: 100%;
								transform: rotateY(180deg);
				}

				&__text {
								font-size: 1.2rem;
								line-height: 2rem;
								color: $font-color-2;
								font-family: 'Ubuntu';
								text-align: left;
				}

				&__title {
								text-align: left;
								line-height: 3rem;
								color: $font-color-2;
				}

				&__subtitle {
								text-align: right;
								font-size: 1.5rem;
				}

				&__phone-image {
								max-width: 450px;
								z-index: 2;
								position: absolute;

								@media screen and (max-width: 767px) {
												transform: unset !important;
								}
				}

				&__row {
								display: flex;
								gap: 1rem;
								flex-wrap: wrap;
								justify-content: center;
								align-items: center;
								transition: margin .3s ease-in-out;

								&.active {
												margin-bottom: 5rem;

												@media screen and (max-width: 767px) {
																margin: 0;
												}
								}

								@media screen and (max-width: 767px) {
												&:nth-of-type(even) {
																flex-direction: column-reverse;
												}

												&:not(:last-child) {
																margin-bottom: 3pc;
												}
								}
				}
}

.hover {
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 5;
				// animation: hover-animation .6s;
				opacity: 0;
				transform: scale(0.4);
				transition: all .4s ease-in-out;

				&.active {
								opacity: 1;
								transform: scale(1);
				}

				&__wrapper {}
}