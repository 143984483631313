@import "vars";
@import "animations";
@import "cursor";
@import "fonts";

//.App {
//				background: $background-color;
//				//min-height: 100vh;
//				overflow: hidden;
//				padding: 0;
//				max-width: 100vw;
//}

body {
	overflow-x: hidden;
	font-family: Kanit, serif !important;
	background: $gradient;
	user-select: none;
}

.body-bg {
	position: fixed;
	z-index: -1;
	// animation-name: bg-image-animation;
	// animation-duration: 15s;
	// animation-iteration-count: infinite;
	// animation-direction: alternate-reverse;
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	opacity: .05;
	filter: grayscale(1);

	@media screen and (max-width: 767px) {
		width: 100vh;
		min-width: 1400px;
	}
}

.title-sticky {
	position: sticky;
	top: calc(50% - 166px / 2);
	padding-top: 1.5rem;
	height: 100%;

	&.left {
		margin-right: 3rem;

		@media screen and (max-width: 767px) {
			margin-right: 0;
		}
	}

	&.right {
		margin-left: 3rem;

		@media screen and (max-width: 767px) {
			margin-left: 0;
		}
	}

	@media screen and (max-width: 767px) {
		position: relative;
		text-align: center;
		top: 0;
		padding-top: 2.5rem;
	}
}

a:not([class]) {
	text-decoration: inherit;
	color: inherit;

	&:hover {
		color: $primary;
	}

	&:visited {
		text-decoration: inherit;
		color: inherit;

		&:hover {
			color: $primary;
		}
	}
}

//a {
//				text-decoration: inherit;
//				color: inherit;
//
//				&:hover {
//								color: $primary;
//				}
//
//				&:visited {
//								text-decoration: inherit;
//								color: inherit;
//
//								&:hover {
//												color: $primary;
//								}
//				}
//}

body {
	max-width: 100%;
	overflow-x: hidden;
}

.components-parent {
	& > div {
		transition: all .6s ease-in-out;
	}
}

strong {
	color: $primary;
}

a.button {
	background: transparent;
	border: 1px solid $primary;
	color: $font-color-1;
	font-family: 'Ubuntu';
	padding: 0.8rem 1.5rem;

	text-transform: uppercase;
	display: inline-block;
	font-weight: bolder;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	// cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	font-size: .8rem;
	border-radius: $border-radius;
	transition: color .15s ease-in-out,
	background-color .15s ease-in-out,
	border-color .15s ease-in-out,
	box-shadow .15s ease-in-out;

	&:hover,
	&.active {
		background-color: $primary;
		color: $background-1;
	}

	&:active {
		background-color: $primary-pressed;
	}
}

.ml11 {
	font-weight: normal;
	// font-size: 3.5em;
	margin-bottom: 2rem;
}

.ml11 .text-wrapper {
	position: relative;
	display: inline-block;
	padding-top: 0.1em;
	padding-right: 0.05em;
	padding-bottom: 0.15em;
}

.ml11 .line {
	opacity: 0;
	position: absolute;
	left: 0;
	height: 100%;
	width: 3px;
	background-color: #fff;
	transform-origin: 0 50%;
}

.ml11 .line1 {
	top: 0;
	left: 0;

	animation-name: line-blinking;
	animation-duration: 800ms;
	animation-delay: 900ms;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

@keyframes line-blinking {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.ml11 .letter {
	display: inline-block;
	line-height: 1em;
	color: $font-color-3;
	font-size: 1.5rem;
	max-width: 350px;
	font-family: 'Avant', sans-serif;
	margin: auto;
	opacity: 0;

	@media screen and (max-width: 767px) {
		font-size: 1.4rem;
	}
}