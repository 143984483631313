@import 'vars';

@keyframes hero-title {
    from {
        transform: scale(2);
        opacity: 0;
        filter: blur(20px);
    }

    to {
        transform: none;
        opacity: 1;
        filter: none;
    }
}

@keyframes bg-image-animation {
    from {
        transform: rotate(135deg) scale(2);
    }

    to {
        transform: scale(1.5);
    }
}

@keyframes mobile-menu {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes menu-after-line {
    from {
        opacity: 0;
        bottom: -15px;
    }

    to {
        opacity: 1;
        bottom: -5px;
    }
}

@keyframes card-animation {
    0% {
        transform: perspective(35em) rotateX(0) rotateY(0) rotateZ(0deg) scale(1);
    }

    50% {
        transform: perspective(35em) rotateX(50deg) rotateY(0) rotateZ(0deg) scale(1);
    }

    100% {
        transform: perspective(35em) rotateX(80deg) rotateY(0) rotateZ(0deg) scale(1) translate3d(10px, -500px, 10px);
    }
}

@keyframes card-flip {
    from {
        transform: perspective(35em) rotateX(0) rotateY(0) rotateZ(0deg);
    }

    to {
        transform: perspective(35em) rotateX(0) rotateY(180deg) rotateZ(0deg);
    }
}

@keyframes test-animation {
    from {
        background: radial-gradient($primary 0%, transparent 0%, transparent 100%);
    }

    to {
        background: radial-gradient($primary 0%, $primary 100%);
    }
}