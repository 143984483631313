@import "../../scss/vars.scss";

.header {
	height: 6rem;
	position: fixed;
	width: 100%;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 2rem;
	transition: all 200ms ease-in-out;
	box-shadow: none;
	background: transparent;

	&.moved {
		background: $dark-half-transparent;
		box-shadow: 0px 0px 14px 4px $dark-half-transparent;
		backdrop-filter: blur(10px);
	}

	@media screen and (max-width: 767px) {
		background: $dark-bg-4;
		box-shadow: 0px 0px 8px 2px $dark-half-transparent;
		padding: 0;
	}

	&__wrapper {
		display: flex;
		gap: 3rem;

		@media screen and (max-width: 767px) {
			width: 100%;
			justify-content: space-between;
			padding: 0 2rem;
		}
	}
}

.hamburger {
	display: none;
	width: 40px;
	height: 40px;
	// cursor: pointer;
	opacity: .8;
	transition: all .2s ease-in-out;

	&:hover {
		opacity: 1;
	}

	@media screen and (max-width: 767px) {
		display: flex;
	}

	&__icon {
	}
}

.menu {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	z-index: 14;

	@media screen and (max-width: 767px) {
		display: none;
		top: 0;
		z-index: 999;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100vh;
		background: $background-4;
		transition: all .3s ease-in-out;

		&.show {
			display: flex;
			animation-name: mobile-menu;
			animation-duration: .5s;
			position: fixed;
			width: 100vw;
			left: 0;
		}
	}

	.menu__item {
		color: $font-color-2;
		font-size: 1rem;
		transition: .2s all ease-in-out;
		font-weight: bold;
		text-decoration: none;
		position: relative;

		&.active {
			color: $primary;

			&::after {
				content: "";
				position: absolute;
				bottom: -5px;
				left: calc(50% - 10px);
				width: 20px;
				border-radius: 4px;
				height: 2px;
				background-color: $primary;
				margin-top: 2px;
				animation: menu-after-line;
				animation-duration: .4s;
			}
		}

		&:hover {
			color: $primary;
		}
	}

	&__close-icon {
		display: none;
		position: absolute;
		top: 2rem;
		right: 2rem;
		width: 40px;
		height: 40px;

		@media screen and (max-width: 767px) {
			display: block;
		}
	}
}

.logo {
	z-index: 1;
}

.logo-image {
	width: 35px;
	height: 35px;
	opacity: .8;
	transition: all .2s ease-in-out;

	&:hover {
		opacity: 1;
	}
}